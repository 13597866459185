import React, { Component } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';

export default class Mdl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enquiry_type: "Product",
            product_name: this.props.title,
            fullname: "",
            email: "",
            phone: "",
            messages: "",
            isVerified: false
        };
    }
    

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post("https://ananddiesandtools.com/sendmail.php", this.state)
            .then(function (response) {
                if (response.data.data === 'success') {
                    swal("Enquiry has been submitted successfully.", "We will contact you as soon as Possible.", "success");
                } else {
                    swal(response.data.message, "warning");
                }
            });
        this.setState({ fullname: '', email: '', phone: '', messages: ''});
        window.location.reload(false);
    };


    onChange = (response) => {
        if (response) {
            this.setState({
                isVerified: true,
            });
        }
        

    }

    render() {
        return (
            <div >
                <div className="modal show  " id="staticBackdrop" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title">{this.props.title}</h2>
                                <button type="button" className="btn-close" onClick={this.props.onHide} ></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmit}  >
                                    <div className="mb-3">
                                        <input type="hidden" name="enquiry_type" id="enquiry_type" value={this.state.enquiry_type} />
                                        <input type="hidden" id="product_name" name="product_name" value={this.state.product_name} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="fullname" className="form-label">Name</label>
                                        <span className="mandatory">*</span>
                                        <input type="text" className="form-control" id="fullname"
                                            name="fullname"
                                            value={this.state.fullname}
                                            onChange={this.handleChange}
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email address</label>
                                        <input type="email" id="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                            name="email" className="form-control"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <span className="mandatory">*</span>
                                        <input type="tel" id="phone" className="form-control" name="phone"
                                        minLength="10" maxLength="13"
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                            required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="messages" className="form-label">Message</label>
                                        <textarea id="messages" rows="3" name="messages" className="form-control"
                                            value={this.state.messages}
                                            onChange={this.handleChange}
                                        ></textarea>
                                    </div>
                                    <ReCAPTCHA
                                        sitekey="6LcK6X8cAAAAALB68ioVLZLlOGHwLqDCtrr8SxYZ"
                                        onChange={this.onChange}
                                    />
                                    <button disabled={!this.state.isVerified} type="submit" className="btn btn-outline-success btn-lg" >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}