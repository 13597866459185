import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import { Footer } from "./Components/layout/Footer";
import { Header } from "./Components/layout/Header";
import { Home } from "./Components/pages/Home";
import { About } from "./Components/pages/About";
import { Products } from "./Components/pages/Products";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import Contactus from './Components/pages/Contactus';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/contactus">
            <Contactus />
          </Route>
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;