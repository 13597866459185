import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Mdl from './Mdl';

export const Home = () => {

    const [showModal, setShowModal] = useState(false);
    const [tempdata, setTempdata] = useState([]);

    const getData = (image, title) => {
        let tempData = [image, title];
        setTempdata(card => [1, ...tempData])
        return setShowModal(true);
    }

    const cardInfo = [
        { image: "tcdies.jpeg", title: "TC Dies" },
        { image: "fluxcoating.jpg", title: "Flux Coating Dies" },
        { image: "spinnerdie.jpg", title: "Spinner Dies" },
        { image: "straighteningdie.jpg", title: "Straightening Dies" },
        // { image: "pcddies.jpg", title: "PCD Dies" },
        { image: "tcwireguide.jpg", title: "TC Wire Guide Bush" },
        { image: "diamondpaste.jpg", title: "Diamond Paste" }
    ];

    return (
        <div>

            <div className="intro ds">
                <div id="carousel" className="carousel slide " data-bs-touch="true" data-bs-ride="carousel" data-bs-pause="false">
                    <div className="carousel-inner">
                        <div className="carousel-item active" >
                            <img src="images/banner1.jpg" className="d-block w-100" alt="First Slide" />
                            <div className="carousel-caption">
                                <h6 >OMEGA Dies and Tools</h6>
                                <h2 className="text-uppercase thin "> <strong className="highlight">Commited</strong> </h2>
                                <h2 className="text-uppercase thin "> To Super Quality </h2>
                                <p className="thin "> <em>Manufacturing world class quality products, which are durable, accurate and economical.</em> </p>
                                <div className="intro-layer"  > <Link to="/about" className="theme_button color1 inverse">
                                    More Info!
                                </Link> </div>
                            </div>
                        </div>
                        <div className="carousel-item ">
                            <img src="images/slide03.jpg" className="d-block w-100" alt="Second Slide" />
                            <div className="carousel-caption  ">
                                <div className="container">
                                    <h6 >OMEGA Dies and Tools</h6>
                                    <h2 className="text-uppercase thin "> <strong className="highlight">Application</strong> </h2>
                                    <h2 className="text-uppercase thin ">Of Technology</h2>
                                    <p className="thin "> <em>Manufacturing world class quality products, which are durable, accurate and economical.</em> </p>
                                    <div className="intro-layer " > <Link to="/about" className="theme_button color1 inverse">
                                        More Info!
                                    </Link> </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item ">
                            <img src="images/slide01.jpg" className="d-block w-100" alt="Third Slide" />
                            <div className="carousel-caption">
                                <h6 >OMEGA Dies and Tools</h6>
                                <h2 className="text-uppercase thin "> <strong className="highlight">Providing</strong> </h2>
                                <h2 className="text-uppercase thin "> Best Products </h2>
                                <p className="thin "> <em>Manufacturing world class quality products, which are durable, accurate and economical.</em> </p>
                                <div className="intro-layer " > <Link to="/about" className="theme_button color1 inverse">
                                    More Info!
                                </Link> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="categories" className="ls section_padding_top_50 section_padding_bottom_100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h2 className="section_header"> <span className="small">Featured</span> Products </h2>
                        </div>
                    </div>
                    <div className="grid ">
                        {cardInfo.map((card, index) => {
                            return (
                                <Card style={{ width: '30rem' }} key={index} className="box shadow p-3 mb-5 bg-white rounded to_animate" >
                                    <Card.Img variant="top" src={card.image} />
                                    <Card.Body>
                                        <Card.Title><h1>{card.title}</h1></Card.Title>
                                        <Button variant="primary" size="lg" className="theme_button color1 inverse wide_button margin_0 "
                                            onClick={() => getData(card.title)}
                                        >Product Enquiry</Button>
                                    </Card.Body>
                                </Card>
                            )
                        })}

                    </div>
                    <div className="row topmargin_30">
                        <div className="col-sm-12 text-center "> <Link to="/products" className="theme_button color1 inverse shadow-lg">View all Products</Link> </div>
                    </div>
                </div>
            </section >

            <section id="fuatures" className="ds columns_padding_0">
                <div className="container-fluid">
                    <div className="row flex-row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="teaser media media-xxs-block inline-block cs hover_icon with_padding big_padding">
                                <div className="media-left media-middle">
                                    <div className="size_big inline-block round light_bg_color thick_border_icon"> <i className="fa fa-truck highlight teaser_icon" aria-hidden="true"></i> </div>
                                </div>
                                <div className="media-body media-middle">
                                    <h4 className="text-uppercase bottommargin_10">Shipping Worldwide</h4>
                                    <p>We ship Worldwide. The Delivery time depends on your Country and Postcode.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="teaser media media-xxs-block inline-block cs main_color3 hover_icon with_padding big_padding">
                                <div className="media-left media-middle">
                                    <div className="size_big inline-block round light_bg_color thick_border_icon"> <i className="fa fa-users highlight teaser_icon" aria-hidden="true"></i> </div>
                                </div>
                                <div className="media-body media-middle">
                                    <h4 className="text-uppercase bottommargin_10">Product Distribution</h4>
                                    <p>Join hands with us to get access to the world class Dies and Tools, our hot selling dies 
                                        and tools can give you a distinct edge over other manufacturers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="teaser media media-xxs-block inline-block cs hover_icon with_padding big_padding">
                                <div className="media-left media-middle">
                                    <div className="size_big inline-block round light_bg_color thick_border_icon"> <i className="fa fa-tags highlight teaser_icon" aria-hidden="true"></i> </div>
                                </div>
                                <div className="media-body media-middle">
                                    <h4 className="text-uppercase bottommargin_10">Discounts &amp; Sale</h4>
                                    <p>Bulk purchase offers are beneficial for both traders and customers. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="features" className="ds color parallax page_features section_padding_top_150 section_padding_bottom_150">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5">
                            <h2 className="section_header"> <span className="small">About</span> OMEGA DIES AND <span className="highlight1">tools</span> </h2>
                            <p className="fontsize_20"> <strong>Standard Dies and Tools</strong> manufactures and exports Wire
                                Drawing Dies. The company was established in year 1980
                                and has positioned itself as the industry leader. </p>
                                <div className="intro-layer " > <Link to="/about" className="theme_button color1 inverse">
                                       Read More!
                                    </Link> </div>
                        </div>
                        <div className="col-md-6 col-lg-offset-1">
                            <div className="teaser media">
                                <div className="media-left">
                                    <div className="teaser_icon size_small highlight1"> <i className="fa fa-users"></i> </div>
                                </div>
                                <div className="media-body">
                                    <h4 className="highlight1 text-uppercase"> We are professionals </h4>
                                    <p className="whyus"> The company takes pride in the attention to detail it provides to each
                                        die during the inspection process. All the geometric facets such as shape, landing
                                        angle, bearing length, exit angle are checked during the inspection process.  </p>
                                </div>
                            </div>
                            <div className="teaser media">
                                <div className="media-left">
                                    <div className="teaser_icon size_small highlight1"> <i className="fa fa-suitcase"></i> </div>
                                </div>
                                <div className="media-body">
                                    <h4 className="highlight1 text-uppercase"> We are trusted </h4>
                                    <p className="whyus">We are one of the leading manufacturers of Wire Drawing T.C. Dies in India.
                                        To attain superior quality, we use only content certified TC Pellets
                                        from M/s Kennametal India Ltd. in wire drawing TC Pellets segment.
                                        Our monthly production is approx 10,000 dies.
                                    </p>
                                </div>
                            </div>
                            <div className="teaser media">
                                <div className="media-left">
                                    <div className="teaser_icon size_small highlight1"> <i className="fa fa-star"></i> </div>
                                </div>
                                <div className="media-body">
                                    <h4 className="highlight1 text-uppercase"> We are experts </h4>
                                    <p className="whyus"> Wire Drawing Dies of MIG Wire has been the flagship product of
                                        Standard Dies and Tools for more than a decade. Our years of experience
                                        go into every die we make and ensure that our customer can draw with
                                        Confidence. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {
                showModal === true ? <Mdl title={tempdata[1]} onHide={() => setShowModal(false)} /> : ''
            }

        </div >

    )
}
export default Home;