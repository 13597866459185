import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Mdl from './Mdl';

export const Products = () => {
    const [showModal, setShowModal] = useState(false);
    const [tempdata, setTempdata] = useState([]);

    const getData = (image, title) => {
        let tempData = [image, title];
        setTempdata(card => [1, ...tempData])
        return setShowModal(true);
    }

    const cardInfo = [
        { image: "tcdies.jpeg", title: "TC Dies" },
        { image: "fluxcoating.jpg", title: "Flux Coating Dies" },
        { image: "spinnerdie.jpg", title: "Spinner Dies" },
        { image: "straighteningdie.jpg", title: "Straightening Dies" },
        // { image: "pcddies.jpg", title: "PCD Dies" },
        { image: "tcwireguide.jpg", title: "TC Wire Guide Bush" },
        { image: "diamondpaste.jpg", title: "Diamond Paste" },
        { image: "diamondpowder.jpg", title: "Diamond Powder" },
        { image: "diamondfile.jpg", title: "Diamond File" },
        { image: "boroncarbide.jpg", title: "Boron Carbide Powder" }
    ];


    return (
        <div>
            <section className="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h2 className="text-uppercase">Products</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ls section_padding_top_100 section_padding_bottom_100 columns_margin_bottom_20">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h2 className="section_header"> <span className="small">All</span> Products </h2>
                        </div>
                    </div>
                    <div className="grid ">
                        {cardInfo.map((card, index) => {
                            return (
                                <Card style={{ width: '30rem' }} key={index} className="box shadow p-3 mb-5 bg-white rounded to_animate" >
                                    <Card.Img variant="top" src={card.image} />
                                    <Card.Body>
                                        <Card.Title><h1>{card.title}</h1></Card.Title>
                                        <Button variant="primary" size="lg" data-toggle="modal" data-bs-target="#staticBackdrop" className="theme_button color1 inverse wide_button margin_0 "
                                            onClick={() => getData(card.title)}
                                        >Product Enquiry</Button>
                                    </Card.Body>
                                </Card>
                            )
                        })}

                    </div>
                </div>
            </section>
            {
                showModal === true ? <Mdl title={tempdata[1]} onHide={() => setShowModal(false)} /> : ''
            }
        </div>
    )
}
export default Products;