import React, { Component } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';

export default class Contactus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enquiry_type: "Contact",
            fullname: "",
            email: "",
            phone: "",
            messages: "",
            isVerified: false
        };
    }

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post("https://ananddiesandtools.com/sendmail.php", this.state)
            .then(function (response) {
                if (response.data.data === 'success') {
                    swal("Enquiry has been submitted successfully.", "We will contact you as soon as Possible.", "success");
                } else {
                    swal(response.data.message, "warning");
                }
            });
        this.setState({ fullname: '', email: '', phone: '', messages: '' });
        window.location.reload(false);
    };

    onChange = (response) => {
        if (response) {
            this.setState({
                isVerified: true,
            });
        }

    }

    render() {
        return (
            <div>
                <section className="page_breadcrumbs ds color parallax section_padding_top_75 section_padding_bottom_75">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h2 className="text-uppercase">Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="ls columns_padding_25 section_padding_top_100 section_padding_bottom_70">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="module-header text-center text-uppercase">Get in touch with us</h1>
                            </div>
                        </div>
                        <div className="col-md-8 to_animate" data-animation="scaleAppear ">
                            <form onSubmit={this.handleSubmit} >
                                <div className="col-sm-12">
                                    <input type="hidden" name="enquiry_type" id="enquiry_type" value={this.state.enquiry_type} />
                                </div>
                                <div className="col-sm-12">
                                    <label htmlFor="fullname" className="form-label">Name</label>
                                    <span className="mandatory">*</span>
                                    <input type="text" className="form-control" id="fullname"
                                        minLength="5"
                                        name="fullname"
                                        value={this.state.fullname}
                                        onChange={this.handleChange}
                                        required />
                                </div>
                                <div className="col-sm-12">
                                    <label htmlFor="email" className="form-label">Email address</label>
                                    <span className="mandatory">*</span>
                                    <input type="email" id="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                        name="email" className="form-control"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        required />
                                </div>
                                <div className="col-sm-12">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <span className="mandatory">*</span>
                                    <input type="tel" id="phone" className="form-control" name="phone"
                                        minLength="10" maxLength="13"
                                        value={this.state.phone}
                                        onChange={this.handleChange}
                                        required />
                                </div>
                                <div className="col-sm-12">
                                    <label htmlFor="messages" className="form-label">Message</label>
                                    <span className="mandatory">*</span>
                                    <textarea id="messages" rows="3" name="messages" className="form-control"
                                    minLength="10"
                                        value={this.state.messages}
                                        onChange={this.handleChange}
                                    required></textarea>
                                </div>
                                <div className="col-sm-12">
                                    <ReCAPTCHA
                                        sitekey="6LcK6X8cAAAAALB68ioVLZLlOGHwLqDCtrr8SxYZ"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="col-sm-12 bottommargin_0">
                                    <div className="topmargin_10">
                                        <button disabled={!this.state.isVerified} type="submit" className="btn btn-outline-success btn-lg " >
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-md-4 to_animate" data-animation="scaleAppear">
                            <ul className="list1 no-bullets no-top-border no-bottom-border">
                                <li>
                                    <div className="media">
                                        <div className="media-left"> <i className="rt-icon2-shop highlight2 fontsize_18"></i> </div>
                                        <div className="media-body">
                                            <h3 className="media-heading grey">Address:</h3> G19, Udyog Kunj, UPSIDC Industrial Area, NH24, Ghaziabad, UP-201009, INDIA </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="media">
                                        <div className="media-left"> <i className="rt-icon2-phone5 highlight2 fontsize_18"></i> </div>
                                        <div className="media-body">
                                            <h3 className="media-heading grey">Phone:</h3><a href="tel:+91-9971602777" title="Call us">9971602777</a> , <a href="tel:+91-9810181182" title="Call us">9810181182</a></div>
                                    </div>
                                </li>

                                <li>
                                    <div className="media">
                                        <div className="media-left"> <i className="rt-icon2-mail highlight2 fontsize_18"></i> </div>
                                        <div className="media-body greylinks">
                                            <h3 className="media-heading grey">Email:</h3>  <a href="mailto:ananddotdies@gmail.com" title="Write us">ananddotdies@gmail.com</a> <br/>       
                                            <a href="mailto:omegadotdies@gmail.com" title="Write us">omegadotdies@gmail.com</a>
                                             </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <h1 className="text-center text-uppercase">How to reach us</h1>
                        <div className="col-md-12" style={{ padding: '30px' }} >
                            <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.4893684303397!2d77.49109981460501!3d28.67500438890931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf2407f04dad9%3A0x2bcf1a10980a4d!2sUdyog%20Kunj%20Substation!5e0!3m2!1sen!2sin!4v1631958326222!5m2!1sen!2sin"
                                style={{ width: '1800px', height: '450px', border: '0' }} allowFullScreen=""></iframe>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
