import React from 'react'
import {Link} from 'react-router-dom';
 
export const Footer = () => {
    return (
        <div className="footer">
            <footer className="page_footer ds ms section_padding_50">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-sm-6 to_animate">
							<div className="widget_text">
								<h3 className="widget-title">Why Us?</h3>
								<p className="whyus"> The company takes pride in the attention to detail it provides
									to each die during the inspection process. All the geometric facets
									such as shape, landing angle, bearing length, exit angle are checked
									during the inspection process. It helps in achieving greater throughtput during
									the production. Production halts are reduced and consistent wire quality is achieved. 
                                </p>
								<p className="topmargin_40">
									 <Link to="/about" className="theme_button">Read More!</Link> 
		&emsp;
									 <a className="theme_button" href="OmegaDiesAndTools9001_2015.pdf" target="_blank" title="Certificate of Registration">ISO Certificate</a>
                         
									 </p>
							</div>
						</div>
						
						<div className="col-md-3 col-sm-6 to_animate">
							<div className="widget_text">
								<h3 className="widget-title">Contact Us</h3>
								<p>G19, Udyog Kunj, UPSIDC Industrial Area, NH24, Ghaziabad, UP-201009, INDIA</p>
								<ul className="list1 no-bullets">
									<li> <i className="rt-icon2-device-phone highlight"></i> <a href="tel:+91-9971602777" title="Call us">9971602777</a>, <a href="tel:+91-9810181182" title="Call us">9810181182</a></li>
									<li> <i className="rt-icon2-mail2 highlight"></i>  <a href="mailto:ananddotdies@gmail.com" title="Write us">ananddotdies@gmail.com</a> <br/>
									<i className="rt-icon2-mail2 highlight"></i> <a href="mailto:omegadotdies@gmail.com" title="Write us">omegadotdies@gmail.com</a>
									</li>
									
								</ul>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 to_animate">
							<div className="widget_text">
							<h3 className="widget-title">Stay Tuned...</h3>
								<div className="media small-teaser">
									<div className="media-left media-middle"> <a href="https://www.facebook.com/Anand-Dies-and-Tools-112097984592116" target="_blank" className="social-icon color-icon border-icon -icon soc-facebook"></a> </div>
									<div className="media-body media-middle"> Facebook </div>
								</div>
								<div className="media small-teaser">
									<div className="media-left media-middle"> <a href="https://www.linkedin.com/company/anand-dies-and-tools/" target="_blank" className="social-icon color-icon border-icon -icon soc-linkedin"></a> </div>
									<div className="media-body media-middle"> LinkedIn </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
            <section className="ds ms page_copyright section_padding_15 with_top_border_container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center text-uppercase">
                            <p className="small-text big">OMEGA DIES AND TOOLS &copy; Copyright 2021, All Rights Reserved, Designed & Developed by: <a href="http://www.cics.co.in" rel="noreferrer" target="_blank" ><span className="lnk">CICS</span></a></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Footer;